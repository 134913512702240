<template>
  <div class="introduce">

    <h1 class="index-h2">产品介绍</h1>

    <el-row>
      <el-col class="col"
              :xs="24"
              :md="12">

        <h2 class="">“启迪智慧教育”青少年腰旗橄榄球</h2>
        
        <div class="hr"></div>
         
        <p>    

腰旗橄榄球一种较为安全的“非冲撞性”运动。腰旗橄榄球运动是以手为主支配球、两队攻守交替，激烈对抗和球不能够落地的球类运动之一。腰旗橄榄球运动技术简单，战术多样，运动负荷适合，趣味性很强。此项运动不但充满了快速的奔跑，敏捷的躲闪、过人技巧和解决困难的战术，更重要的是，作为一项团队运动，它对球员的合作精神有着极高的要求。是针对青少年群体的一个普及型项目，具有和其他球类运动所不具备的技、战术特点。

        </p>
        
      </el-col>


      <el-col :xs="24"
              :md="12">

        <div class="  hvr-grow">
          <el-image :src=" require('@/assets/ee30.jpg')"
                    
                    class="img-box ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>

      </el-col>

    </el-row>
  
      
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
            <el-image :src=" require('@/assets/ee31.jpg')"

                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

 <h1 class="index-h2">项目优势</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee32.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

  

  </div>
</template>


<script>
export default {
  data () {
    return {
      url: require('@/assets/ee20-1.jpg'),
       url2: require('@/assets/ee20.jpg')

    }
  },
  methods: {

  },
  metaInfo: {
    title: '北京启迪邦妙文体科技有限公司-体育教育', 
    meta: [{                 
      name: '启迪邦妙,妙音乐教室,智能音乐教室,启迪春雨,启迪智慧教育,智能钢琴,钢琴陪练,钢琴考级,智能架子鼓,腰旗橄榄球',
      content: '北京启迪邦妙文体科技有限公司长年致力于青少年素质教育智能化平台建设工作，拥有多项智能教育领域专利、软件著作权等产品成果，是启迪控股集团内专业从事青少年素质教育服务的控股子公司。'
    }]      
  } 
  //   components: {

  //   },
}
</script>

<style lang="less" scoped>
.introduce {
  padding-bottom: 100px;
}
.el-row {
  display: flex;
  align-items: center;
}
.col {
  text-align: left;
   
  h3 {
    margin-bottom: 5px;
  }
  .hr {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    background-color: whitesmoke;
  }
  .msg {
    font-size: 14px;
  }

  p {
    font-size: 16px;
    line-height: 35px;
  }

  .img-box{
    width: 300px;
  }
  .img-box2{
    width: 1200px;
  }
}
</style>
<style lang="less" >
</style>